<template>
  <div>
    <div class="drag-panel">
      <div class="drag-panel-header">
        <span class="header-title">
          <span class="name">&nbsp;Help&nbsp;</span>
        </span>
      </div>
      <div class="drag-panel-content">
<!--
    <b-card class="mt-0 documentation-panel" header="Help">
-->
      <b-container fluid class="container">
        <b-row>
          <h2 class="mt-4"><b>Overview</b></h2>
          <p class="mt-2">
            The CREW Asthma Conceptual Models application incorporates three main panes: the <i>Models</i> pane displays a selected conceptual model, the <i>Publications</i>  pane shows scientific literature associated with a selected node in the model, and the <i>Node Properties</i> pane shows properties of the entity that the selected node represents.
          </p>
        </b-row>
        <b-row>
          <h2 class="mt-4"><b>The Models pane</b></h2>
          <p class="mt-2">
            Tabs at the top of the Models pane allow the user to select the model to be displayed.  Currently, the application contains two models: </i>17q12-21 GxE Interactions</i> and <i>GSDMB Function</i>.
          </p>
          <p>
            Each conceptual model consists of a set of nodes and a set of directed edges connecting the nodes.  Nodes represent entities such as SNPs, environmental factors, proteins, and disease phenotypes. Edges represent specific relationships among the entities, such as <i>binds</i> and <i>involved-in</i>.
          </p>
          <p>
            Some entities are shown nested inside of other, more general entities.  For example, the node for the ORMDL3 gene is nested inside of the node for the 17q12-21 locus.  This nesting is used to depict <i>part-of</i> and <i>specialization-of</i> relationships.
          </p>
          <p>
            Hexagonal nodes represent interactions among entities (e.g. gene x environment interactions).  Each such node has two or more incoming edges indicating the interacting entities.
          </p>
        </b-row>
        <b-row>
          <h2 class="mt-4"><b>The Publications pane</b></h2>
          <p class="mt-2">
            When the mouse is used to select a node in the <i>Models pane</i>, relevant publications are shown in the <i>Publications</i> pane.  The publications have been collected using automated queries to <a href="https://pubmed.ncbi.nlm.nih.gov" target="_blank">PubMed</a> that search for articles mentioning both the concept represented by the node and asthma.  Publications shown in blue text are those that have been curated as highly relevant by CREW investigators.
          </p>
          <p>
            Each publication listing provides a hyperlink to the PubMed record for the publication.
          </p>
          <p>
            The ordering of the listed publications can be controlled using the <i>Sort</i> button in the pane.  The listed publications can be filtered by entering text in the Search box (e.g. to list only publications with a certain author, type that author’s last name in the Search box).
          </p>
        </b-row>
        <b-row>
          <h2 class="mt-4"><b>The Node Properties pane</b></h2>
          <p class="mt-2">
            When the mouse is used to select a node in the <i>Models</i> pane, several properties of the node entity are shown in the <i>Node Properties</i> pane.  These include a type label for the entity and a link to a standard database (e.g. <a href="https://www.ncbi.nlm.nih.gov/snp/" target="_blank">dbSNP</a> for a SNP) or ontology reference for the entity.
          </p>
        </b-row>
        <b-row>
          <h2 class="mt-4"><b>Controlling the display of a model</b></h2>
          <p class="mt-2">
            The user can vary the display of a model in the <i>Models</i> pane by using a trackpad/mouse to zoom and pan.  Additionally, nodes can be dragged to new locations in the pane.
          </p>
          <p>
            Subsets of the model can be selected for display using the <i>Search</i> box in the <i>Models</i> pane.  The search will identify each node whose name matches the search query, and display all paths that lead to this node, and all paths that lead from this node to the asthma node.
          <p>
          </p>
        </b-row>

          </b-container>
<!--
        </b-card>
-->
      </div>
      <div class="drag-panel-footer">
      </div>
    </div>
  </div>
</template>

<script>
  import $ from 'jquery'
  import EventBus from './eventbus'

  export default {
    name: 'HelpPanel',
    components: {
    },
    data () {
      return {
      }
    },
    computed: {
    },
    mounted() {
      var self = this
    },
    methods: {
    }
  }
</script>
<style scoped>
  p {
    font-size: 14px;
  }
  .noscroll {
    overflow: hidden;
  }
  .graph-view {
    width: 100%;
  }
  .container {
    height: 100%;
    overflow-y: scroll; /* don't show content that exceeds my height */
  }
    .drag-panel-content {
      position: fixed;
      top: 35px;
      bottom: 30px;
      left: 0;
      right: 0;
      background-color: whitesmoke;
      border: 1px solid black;
      //overflow: auto;
      //-webkit-overflow-scrolling: touch;
    }

    .drag-panel-footer {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      height: 30px;
      background-color: lightgray;
    }

  .documentation-panel {
    width: 100%;
    height: 100%;
    background-color: whitesmoke;
  }
  .documentation-workarea {
      //height: 75%;
      position: fixed;
      //top: 80px;
      top: 45px;
      bottom: 150px;
      left: 0;
      right: 0;
      margin-left: 2px;
      margin-right: 2px;
      //background-color: lightblue;
      //border: 1px solid black;
    }
</style>
